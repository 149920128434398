import React from "react";
import { Button } from "react-bootstrap";
import { format, differenceInMinutes } from "date-fns";

import styles from "src/components/calendar/calendar.module.scss";

export function Event({ event }) {
    return (
        <div className={styles.calendarEventContent}>
            <div className={`${event.hasDeclined ? styles.declined : ""}`}>
                {event.retailer_name && `${event.retailer_name}: `}
                {event.name}
            </div>
            <small>
                <span className={styles.calendarEventTime}>
                    {format(event.start, "h:mmaaaaa")} (
                    {differenceInMinutes(event.end, event.start)}m)
                </span>
                {event.location_name && <> &middot; {event.location_name}</>}
            </small>
        </div>
    );
}

export function AgendaEvent({ event, onSelectEvent }) {
    return (
        <div className={styles.calendarEventContent}>
            <div>
                <span className={`${event.hasDeclined ? styles.declined : ""}`}>
                    {event.retailer_name && `${event.retailer_name}: `}
                    {event.name}
                </span>
            </div>
            <small>{event.location_name}</small>
            {/* <Button size="sm" variant="primary" onClick={e => onSelectEvent(event, e)}>View details</Button> */}
        </div>
    );
}
