import _ from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { format, parse, isPast } from "date-fns";
import { Button, Form, Col } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import clsx from "clsx";

import Api from "src/data/api";
import CloseIcon from "public/assets/close.svg";
import styles from "src/components/calendar/calendar.module.scss";
import { useDispatch } from "react-redux";
import { showNotification } from "src/redux/notificationActions";
import { CALENDAR_MESSAGES, ERROR_MESSAGES, SYNC_MESSAGES } from "src/constants/messages";
import { NOTIFICATION_TYPES } from "src/constants/notificationTypes";
import { NOTIFICATION_HEADERS } from "src/constants/notificationHeaders";
import { TIME_BLOCK_FIELD_PATTERN } from "../RetailerSchedule/ScheduleConstants";
import { getLocalISODate, roundDecimals } from "src/utils/helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function AppointmentForm({
    user,
    appointment,
    onChange,
    onRequestClose,
    readOnly = false,
}) {
    const dispatch = useDispatch();
    const [submitEnabled, allowSubmit] = useState(true);
    const [lastSaveState, setLastSaveState] = useState(appointment);
    // const [availableTimes, setAvailableTimes] = useState([]);
    const [availableLocations, setAvailableLocations] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const isAdmin = user.role = "admin"
    const [locationsMap, setLocationsMap] = useState({});

    const isNewAppt = !appointment.appointment_id;
    const isPastAppt =
        appointment.appointment_id &&
        isPast(parse(appointment.datetime, "yyyy-MM-dd HH:mm:ss", new Date()));
    const isChanged = useMemo(() => {
        return !_.isEqual(appointment, lastSaveState);
    }, [appointment]);

    const getAvailableLocations = async () => {
        try {
            const { data } = await Api.request({
                url: `/retailer/appointments/locations`,
            });

            let locations = data;

            if (user.location_lock) {
                locations = data.filter(
                    (x) => x.location_id === user.location_lock
                );
            }

            setAvailableLocations(locations);

            const availableLocationsMap = locations.reduce((acc, curr) => {
                acc[curr.location_id] = curr.address;
                return acc;
            }, {});

            setLocationsMap(availableLocationsMap);
        } catch (err) {
            console.error(err);
        }
    };

    const handleApptEdit = async (e, data) => {
        e.preventDefault();
        allowSubmit(false);
        if (readOnly) {
            return;
        }

        try {
            await Api.request({
                method: "POST",
                url: appointment.appointment_id
                    ? `/retailer/appointment/${appointment.appointment_id}/edit`
                    : `/retailer/appointment`,
                data: {
                    ...appointment,
                    appt_date: getLocalISODate(appointment.appt_date),
                    status: appointment.status || null,
                    ...data,
                },
            });

            setLastSaveState(appointment);
            onRequestClose({ update: true });
            setEditMode(false);
            dispatch(
                showNotification(
                    CALENDAR_MESSAGES.SAVE_APPOINTMENT,
                    NOTIFICATION_TYPES.SUCCESS,
                    NOTIFICATION_HEADERS.CALENDAR_APPOINTMENT
                )
            );
        } catch (err) {
            console.error(err);
            dispatch(
                showNotification(
                    ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                    NOTIFICATION_TYPES.ERROR,
                    NOTIFICATION_HEADERS.OOPS
                )
            );
        }

        allowSubmit(true);
    };

    const handleApptCancel = async (e) => {
        e.preventDefault();
        allowSubmit(false);
        if (readOnly) {
            return;
        }

        const result = window.confirm(
            "Are you sure you want to cancel this appointment?"
        );
        if (result) {
            try {
                await Api.request({
                    method: "POST",
                    url: `/retailer/appointment/${appointment.appointment_id}/cancel`,
                });

                onRequestClose({ update: true });
            } catch (err) {
                console.error(err);
            }
        }

        allowSubmit(true);
    };

    const handleUpdateAppointmentStatus = async (status) => {
        try {
            await Api.request({
                method: "PUT",
                url: `/retailer/appointment/${appointment.appointment_id}/status`,
                data: { status },
            });
            dispatch(
                showNotification(
                    CALENDAR_MESSAGES.SAVE_APPOINTMENT,
                    NOTIFICATION_TYPES.SUCCESS,
                    NOTIFICATION_HEADERS.CALENDAR_APPOINTMENT
                )
            );
        } catch (error) {
            console.error(err);
            dispatch(
                showNotification(
                    ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                    NOTIFICATION_TYPES.ERROR,
                    NOTIFICATION_HEADERS.OOPS
                )
            );
        }
    };

    const syncPostAppointment = async (appointmentInfo) => {
        try {
            const orderSync = await Api.request({
                method: "POST",
                url: "/retailer/appointments/sync",
                data: appointmentInfo
            })
            console.log(orderSync)
            if (false) {
                dispatch(
                    showNotification(
                        SYNC_MESSAGES.ORDER_NOT_FOUND,
                        NOTIFICATION_TYPES.WARNING,
                        NOTIFICATION_HEADERS.SYNC
                    )
                )
            }
            dispatch(
                showNotification(
                    SYNC_MESSAGES.SYNC_SUCCESS,
                    NOTIFICATION_TYPES.SUCCESS,
                    NOTIFICATION_HEADERS.SYNC
                )
            )
        } catch (error) {
            console.error(error);
            dispatch(
                showNotification(
                    SYNC_MESSAGES.SYNC_FAILED,
                    NOTIFICATION_TYPES.ERROR,
                    NOTIFICATION_HEADERS.OOPS
                )
            )
        }
    }

    useEffect(() => {
        if (!readOnly && appointment.appt_type === "instore") {
            getAvailableLocations();
        }
    }, [appointment.appt_type]);

    const fittingRoomItems =
        appointment?.cart?.filter((x) => !x.isInStoreCloseBy) || [];
    const fittingRoomCloseBy =
        appointment?.cart?.filter((x) => x.isInStoreCloseBy) || [];
    return (
        <div className={styles.appointment}>
            <div className={styles.apptFormHeader}>
                <h3 className={styles.apptFormHeader__Heading}>
                    {isNewAppt ? "New appointment" : "Booking Details"}
                </h3>
                <Form.Control
                    as="select"
                    custom
                    value={appointment.status || ""}
                    onChange={(e) => {
                        onChange({ status: e.target.value });
                        handleUpdateAppointmentStatus(e.target.value);
                    }}
                    className={`${styles.statusSelect} form-control`}
                    disabled={isNewAppt}
                >
                    <option value="" disabled hidden>
                        Status
                    </option>
                    <option value="no-show">No-show</option>
                    <option value="show-no-purchase">
                        Showed / Didn't Buy
                    </option>
                    <option value="show-and-purchase">Showed / Bought</option>
                </Form.Control>
                <button
                    className={styles.apptFormClose}
                    onClick={onRequestClose}
                    aria-label="Close"
                >
                    <CloseIcon />
                </button>
            </div>

            <Form onSubmit={handleApptEdit}>
                <Form.Group controlId="input-name">
                    <label>Name</label>
                    <input
                        className="form-control"
                        type="text"
                        value={appointment.name}
                        onChange={(e) => onChange({ name: e.target.value })}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="input-email">
                    <label>Email</label>
                    {editMode || isNewAppt ? (
                        <input
                            className="form-control"
                            type="email"
                            value={appointment.email}
                            onChange={(e) =>
                                onChange({ email: e.target.value })
                            }
                            required={isNewAppt}
                            readOnly={!isNewAppt}
                        />
                    ) : (
                        <a
                            className="form-control"
                            href={`mailto:${appointment.email}`}
                        >
                            {appointment.email}
                        </a>
                    )}
                </Form.Group>

                <Form.Group controlId="input-phone">
                    <label>Phone</label>

                    {editMode || isNewAppt ? (
                        <PhoneInput
                            value={appointment.phone_number}
                            onChange={(_, __, ___, formattedValue) =>
                                onChange({ phone_number: formattedValue })
                            }
                            country={"us"}
                            enableAreaCodes={true}
                            enableSearch
                            disableSearchIcon
                            inputClass="form-control"
                            inputStyle={{ width: "100%" }}
                        ></PhoneInput>
                    ) : (
                        <a
                            className="form-control"
                            href={`sms:${appointment.phone_number}`}
                        >
                            {appointment.phone_number}
                        </a>
                    )}
                </Form.Group>

                {isNewAppt && (
                    <div
                        style={{ margin: "15px 0 10px" }}
                        className={clsx(
                            "radioToggleGroup",
                            "radioToggleGroupCentered"
                        )}
                    >
                        <input
                            type="radio"
                            id="tote-store-type-instore"
                            name="store-type"
                            value="instore"
                            checked={appointment.appt_type === "instore"}
                            onChange={(e) =>
                                onChange({ appt_type: e.target.value })
                            }
                        />
                        <label htmlFor="tote-store-type-instore">
                            In Person
                        </label>
                        <input
                            type="radio"
                            id="tote-store-type-virtual"
                            name="store-type"
                            value="virtual"
                            checked={appointment.appt_type === "virtual"}
                            onChange={(e) =>
                                onChange({ appt_type: e.target.value })
                            }
                        />
                        <label htmlFor="tote-store-type-virtual">Virtual</label>
                    </div>
                )}

                {isNewAppt && appointment.appt_type === "instore" && (
                    <Form.Group controlId="input-location" className="mb-2">
                        <label>Location</label>
                        <Form.Control
                            as="select"
                            custom
                            style={{ minWidth: 0 }}
                            onChange={(e) =>
                                onChange({
                                    location_id: e.target?.value || null,
                                    location_address:
                                        locationsMap[e.target?.value],
                                })
                            }
                            required
                        >
                            <option>Select...</option>
                            {availableLocations.map((location) => (
                                <option
                                    key={location.location_id}
                                    value={location.location_id}
                                    selected={
                                        appointment.location_id ===
                                        location.location_id
                                    }
                                >
                                    {location.name} -{" "}
                                    {location.address.replace(/\r?\n/, ", ")}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                )}
                {!isNewAppt && (
                    <Form.Group controlId="input-location">
                        <label>Location</label>
                        <input
                            className="form-control"
                            value={
                                appointment.location_id
                                    ? appointment.customer_address
                                        ? appointment.customer_address
                                        : `${appointment.location_name
                                        } - ${appointment.address.replace(
                                            /\r?\n/,
                                            ", "
                                        )}`
                                    : "Virtual"
                            }
                            readOnly
                        />
                    </Form.Group>
                )}
                {!isNewAppt && appointment.creator !== "retailer" ? (
                    <Form.Group>
                        <label>Date/Time</label>
                        <Form.Row className={styles.dateTimeWrapper}>
                            <Col className={styles.dateTimeCol} xs={6}>
                                {isPastAppt ? (
                                    <div className="flatpickrInputContainer">
                                        <input
                                            className="form-control"
                                            value={format(
                                                appointment.appt_date,
                                                "MM/dd/yyyy"
                                            )}
                                            readOnly
                                        />
                                    </div>
                                ) : (
                                    <Flatpickr
                                        className="form-control"
                                        value={format(
                                            appointment.appt_date,
                                            "MM/dd/yyyy"
                                        )}
                                        onChange={(selDate, dateStr) =>
                                            onChange({ appt_date: selDate[0] })
                                        }
                                        render={(
                                            { defaultValue, value, ...props },
                                            inputRef
                                        ) => (
                                            <div className="flatpickrInputContainer">
                                                <input
                                                    {...props}
                                                    defaultValue={defaultValue}
                                                    ref={inputRef}
                                                    placeholder="Select date..."
                                                    required
                                                />
                                            </div>
                                        )}
                                        options={{
                                            enableTime: false,
                                            dateFormat: "m/d/Y",
                                            position: "above",
                                            minDate: "today",
                                        }}
                                    />
                                )}
                            </Col>
                            <Col className={styles.dateTimeCol} xs={6}>
                                <input
                                    className="form-control"
                                    value={appointment.appt_time}
                                    required={!isPastAppt}
                                    disabled={isPastAppt}
                                    pattern={TIME_BLOCK_FIELD_PATTERN}
                                    onChange={(e) => {
                                        onChange({
                                            appt_time: e.target.value,
                                        });
                                    }}
                                    type="time"
                                    step="60"
                                />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                ) : (
                    <Form.Group>
                        <Form.Row
                            className={styles.dateTimeWrapper}
                            style={{
                                flexWrap: "nowrap",
                                justifyContent: "space-between",
                                marginRight: 0,
                                marginLeft: 0,
                            }}
                        >
                            <label className="mr-2">
                                Date
                                {isPastAppt ? (
                                    <div className="flatpickrInputContainer">
                                        <input
                                            className="form-control"
                                            value={format(
                                                appointment.appt_date,
                                                "MM/dd/yyyy"
                                            )}
                                            readOnly
                                        />
                                    </div>
                                ) : (
                                    <Flatpickr
                                        className="form-control"
                                        value={
                                            appointment.appt_date
                                                ? format(
                                                    appointment.appt_date,
                                                    "MM/dd/yyyy"
                                                )
                                                : null
                                        }
                                        onChange={(selDate, dateStr) =>
                                            onChange({ appt_date: selDate[0] })
                                        }
                                        render={(
                                            { defaultValue, value, ...props },
                                            inputRef
                                        ) => (
                                            <div className="flatpickrInputContainer">
                                                <input
                                                    {...props}
                                                    defaultValue={defaultValue}
                                                    ref={inputRef}
                                                    placeholder="Select date..."
                                                    required
                                                />
                                            </div>
                                        )}
                                        options={{
                                            enableTime: false,
                                            dateFormat: "m/d/Y",
                                            position: "above",
                                            minDate: "today",
                                        }}
                                    />
                                )}
                            </label>
                            <label className="mr-2" htmlFor="appt-time-start">
                                Start
                                <input
                                    id="appt-time-start"
                                    className="form-control"
                                    value={appointment.appt_time}
                                    required={!isPastAppt}
                                    disabled={isPastAppt}
                                    pattern={TIME_BLOCK_FIELD_PATTERN}
                                    onChange={(e) => {
                                        onChange({
                                            appt_time: e.target.value,
                                        });
                                    }}
                                    type="time"
                                    step="60"
                                />
                            </label>
                            <label htmlFor="appt-time-end">
                                End
                                <input
                                    id="appt-time-end"
                                    className="form-control"
                                    value={appointment.appt_time_end}
                                    required={!isPastAppt}
                                    disabled={isPastAppt}
                                    pattern={TIME_BLOCK_FIELD_PATTERN}
                                    onChange={(e) => {
                                        onChange({
                                            appt_time_end: e.target.value,
                                        });
                                    }}
                                    type="time"
                                    step="60"
                                />
                            </label>
                        </Form.Row>
                    </Form.Group>
                )}

                {!isNewAppt && (
                    <>
                        {appointment.cart.length > 0 ? (
                            <section className={styles.apptQuestionnaire}>
                                {fittingRoomItems.length ? (
                                    <>
                                        <h3>{user.noun}</h3>
                                        <ul>
                                            {fittingRoomItems.map((item, i) => (
                                                <li key={i}>
                                                    {appointment.enabled_product_vender_info &&
                                                        item.productVendor
                                                        ? `(${item.productVendor}) `
                                                        : ""}
                                                    {item.productTitle} (
                                                    {
                                                        item.productVariant
                                                            ?.public_title
                                                    }
                                                    ){" "}
                                                    {appointment.enabled_sku_info &&
                                                        item.productVariant.sku
                                                        ? `(${item.productVariant.sku})`
                                                        : null}
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : null}

                                {fittingRoomCloseBy.length ? (
                                    <>
                                        <h3>
                                            {user.noun} Items In a Store Close
                                            By
                                        </h3>
                                        <ul>
                                            {fittingRoomCloseBy.map(
                                                (item, i) => (
                                                    <li key={i}>
                                                        {appointment.enabled_product_vender_info &&
                                                            item.productVendor
                                                            ? `(${item.productVendor}) `
                                                            : ""}
                                                        {item.productTitle} (
                                                        {
                                                            item.productVariant
                                                                ?.public_title
                                                        }{" "}
                                                        {appointment.enabled_sku_info &&
                                                            item.productVariant.sku
                                                            ? `(${item.productVariant.sku})`
                                                            : null}
                                                        ){" "}
                                                        {item.storeCloseBy
                                                            ? `(${item.storeCloseBy})`
                                                            : null}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </>
                                ) : null}
                            </section>
                        ) : (
                            <section className={styles.apptQuestionnaire}>
                                <h3>Questionnaire</h3>
                                {appointment.questions.length > 0 ? (
                                    <ol>
                                        {appointment.questions.map(
                                            (question, i) => (
                                                <li key={i}>
                                                    {question.question}
                                                    <ul>
                                                        {question.answers.map(
                                                            (answer, j) => (
                                                                <li
                                                                    key={j}
                                                                    className="font-weight-bold"
                                                                >
                                                                    {answer}
                                                                </li>
                                                            )
                                                        )}
                                                        {question.answers
                                                            .length === 0 && (
                                                                <li className="font-weight-bold">
                                                                    (no answer)
                                                                </li>
                                                            )}
                                                    </ul>
                                                </li>
                                            )
                                        )}
                                    </ol>
                                ) : (
                                    <p>
                                        Customer did not answer any questions.
                                    </p>
                                )}
                            </section>
                        )}
                    </>
                )}
                {isNewAppt ||
                    (editMode && appointment.creator === "retailer") ? (
                    <>
                        <label htmlFor="additionalInfo">Additional Info</label>
                        <textarea
                            className="form-control"
                            name="additionalInfo"
                            id="additionalInfo"
                            value={appointment.additional_info}
                            onChange={(e) =>
                                onChange({ additional_info: e.target.value })
                            }
                            rows="3"
                        ></textarea>
                    </>
                ) : null}
                {!isNewAppt && !editMode && appointment.additional_info ? (
                    <>
                        <h3>Additional Instructions</h3>
                        <p>{appointment.additional_info}</p>
                    </>
                ) : null}
                {!readOnly && (
                    <div className="my-3">
                        {!isPastAppt ?
                            <>
                                {editMode || isNewAppt ? (
                                    <Button
                                        className="mr-3"
                                        variant="outline-primary"
                                        type="submit"
                                        disabled={!isChanged || !submitEnabled}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        className="mr-3"
                                        variant="outline-primary"
                                        type="button"
                                        onClick={() => setEditMode(true)}
                                    >
                                        Edit
                                    </Button>
                                )}
                                {!isNewAppt && (
                                    <Button
                                        className="mr-3"
                                        variant="outline-danger"
                                        type="button"
                                        disabled={!submitEnabled}
                                        onClick={handleApptCancel}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <Button
                                    variant="outline-secondary"
                                    type="button"
                                    onClick={onRequestClose}
                                >
                                    Close
                                </Button>
                            </> : <>
                                <h3 style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span>Post Appointment Summary</span>
                                    <span>
                                        <label style={{ width: "unset", marginRight: "15px" }} htmlFor="so_shopify_order_id">Shopify Order ID</label>
                                        <input
                                            type="so_shopify_order_id"
                                            id="so_shopify_order_id"
                                            value={appointment.so_shopify_order_id}
                                            onChange={(e) =>
                                                onChange({ so_shopify_order_id: e.target.value })
                                            }
                                        />
                                    </span>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            console.log("sync")
                                            syncPostAppointment(appointment)
                                        }}
                                        className={styles.syncButton}
                                    >
                                        Sync
                                    </button>
                                </h3>
                                {appointment.so_shopify_order_id &&
                                    <div className={styles.appointmentInfoContainer}>
                                        <h2>Order Details</h2>
                                        <table>
                                            <tbody>
                                                {/* <tr>
                                                <th>Appointment ID</th>
                                                <td>{appointment.so_appointment_id}</td>
                                            </tr>
                                            <tr>
                                                <th>Customer ID</th>
                                                <td>{appointment.so_customer_id}</td>
                                            </tr> */}
                                                <tr>
                                                    <th>Shopify Order ID</th>
                                                    <td>{appointment.so_shopify_order_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total Price</th>
                                                    <td>{parseFloat(appointment.so_total_price).toFixed(2)}</td>

                                                </tr>
                                                <tr>
                                                    <th>Refunded Amount</th>
                                                    <td>{parseFloat(appointment.so_refunded_amount).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created At</th>
                                                    <td>{new Date(appointment.so_created_at).toDateString()}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <h2>Purchased Items</h2>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Variant</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {appointment.so_line_items && JSON.parse(appointment.so_line_items).map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.title}</td>
                                                            <td>{item.variant_title}</td>
                                                            <td>{item.quantity}</td>
                                                            <td>{item.price}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        {appointment.so_refunded_items_count > 0 && <>
                                            <h2>Refunded Items</h2>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Quantity Refunded</th>
                                                        <th>Refund Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {console.log(appointment.so_refunded_items_count)}
                                                    {appointment.so_refunded_items && JSON.parse(appointment.so_refunded_items).map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.item_name}</td>
                                                            <td>{item.quantity_refunded}</td>
                                                            <td>{item.refund_amount}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>}
                                    </div>}
                            </>}
                    </div>
                )}

            </Form>
        </div>
    );
}

export default AppointmentForm;
