import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';

import * as actions from 'src/redux/actions';
import Loader from 'src/components/loader/fullscreen';

export default function withAuth(WrappedComponent, isAdminOnly = false) {
    return connect((store) => ({ loggedIn: store.user.loggedIn, user: store.user }))((props) => {
        const router = useRouter();
        const [authorized, setAuthorized] = useState(false);

        useEffect(() => {
            if (props.loggedIn) {
                return setAuthorized(true);
            }

            props.dispatch(actions.loadUser()).then((resp) => {
                const user = resp?.data;
                if (!user) {
                    return router.push({
                        pathname: '/login',
                        query: {
                            next: router.asPath,
                        }
                    });
                }
                if (isAdminOnly && user.role !== 'admin') {
                    return router.push('/404');
                }

                setAuthorized(!!user);
            });
        }, [props.loggedIn]);

        return (
            authorized ? <WrappedComponent {...props} /> : <Loader />
        );
    });
}
