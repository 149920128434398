import _ from 'lodash';

import Api from 'src/data/api';
import * as types from './actionTypes';

export function loadUser() {
    return async (dispatch) => {
        dispatch({
            type: types.loadUserStart
        });

        try {
            const resp = await Api.request({ url: '/user/me' });
            let data = resp.data;
            if (!_.isObject(data)) {
                data = null;
            }

            return dispatch({
                type: types.loadUserSuccess,
                data,
            });
        } catch (err) {
            console.error(err.toJSON());
            dispatch({
                type: types.loadUserFail
            });
        }
    };
}

export const createCustomerSuccess = (customerId) => ({
    type: types.CUSTOMER_CREATION_SUCCESS,
    payload: customerId,
});

