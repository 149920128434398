// schedule page messages
export const SCHEDULE_MESSAGES = {
    UPDATE_SCHEDULE_SUCCESS: "Schedule has been successfully updated.",
};

// settings page messages
export const SETTINGS_MESSAGES = {
    UPDATE_WIDGET_TEXT_CONTENT:
        "Widget text content has been successfully updated.",
    UPDATE_WIDGET_INSTALL_CODE_SETTINGS_SUCCESS:
        "Widget settings have been successfully updated.",
    UPDATE_RETAILER_SETTINGS_SUCCESS:
        "Settings have been successfully updated.",
    UPDATE_WIDGET_SETTINGS: "Widget settings have been updated successfully.",
    UPDATE_WIDGET_IMAGE_SUCCESS: "Widget image has been successfully updated.",
    UPDATE_LOCATION_EMAILS_SUCCESS:
        "Location emails have been successfully updated.",
    SELECT_PLAN_WARNING:
        "The number of active locations is more than the max amount for the plan you are trying to select.",
};

// questionnaire page messages
export const QUESTIONNAIRE_MESSAGES = {
    SAVE_QUESTIONS_SUCCESS: "Question has been successfully saved.",
    DELETE_QUESTION_SUCCESS: "Question has been successfully deleted.",
    REORDER_QUESTIONS_SUCCESS: "Questions have been successfully reordered",
    SAVE_QUESTIONNAIRE_SETTINGS_SUCCESS:
        "Questionnaire settings have been successfully saved.",
};

// calendar page messages
export const CALENDAR_MESSAGES = {
    SAVE_APPOINTMENT: "Appointment has been successfully saved.",
};

//email customization page messages
export const EMAIL_CUSTOMIZATION_MESSAGES = {
    SAVE_CHANGES: "Changes have been successfully saved.",
};

export const SUBSCRIPTION_REQUEST_MESSAGES = {
    REQUEST_SUCCESSFUL: "Thank You. A TOTE rep will be in touch very soon!",
};

export const SUBSCRIPTIONS_MESSAGED = {
    SUBSCRIPTION_UPDATE_SUCCESS: "Subscription successfully updated.",
};

export const QUEUES_MESSAGES = {
    QUEUE_CREATE_SUCCESS: "Queue successfully created.",
    QUEUE_UPDATE_SUCCESS: "Queue successfully updated.",
    QUEUE_DELETE_SUCCESS: "Queue successfully deleted.",
    QUEUE_REORDER_SUCCESS: "Queues successfully reordered.",
};

export const QUEUE_APPOINTMENTS_MESSAGES = {
    QUEUE_APPOINTMENT_CREATE_SUCCESS: "Appointment successfully created.",
    QUEUE_APPOINTMENT_UPDATE_SUCCESS: "Appointment successfully updated.",
    QUEUE_APPOINTMENT_CANCEL_SUCCESS: "Appointment successfully cancelled.",
    QUEUE_APPOINTMENT_COMPLETE_SUCCESS: "Appointment successfully completed.",
    QUEUE_APPOINTMENT_MOVE_SUCCESS:
        "Appointment successfully moved to next queue.",
    QUEUE_APPOINTMENTS_REORDER_SUCCESS: "Appointment successfully reordered.",
};

export const QUEUE_ERROR_MESSAGES = {
    UNEDITABLE_QUEUE: "You cannot move appointments into this queue",
};

export const EMPLOYEES_MESSAGES = {
    EMPLOYEE_INVITATION_SEND_SUCCESS: "Employee successfully invited.",
};

export const WEBAPP_MESSAGES = {
    WEBAPP_SETTINGS_UPDATE_SUCCESS: "Webapp settings successfully updated.",
};

// error messages
export const ERROR_MESSAGES = {
    SOMETHING_WENT_WRONG: "Something went wrong, please try again.",
};

export const SYNC_MESSAGES = {
    ORDER_NOT_FOUND: "No order found for this appointment.",
    SYNC_FAILED: "Sync failed. Please reach out to Tote support.",
    SYNC_SUCCESS: "Order synced with appointment, please refresh if data doesn't appear immediately."
}