export const timeSlotObject = { startTime: "", endTime: "" };
export const TIME_BLOCK_FIELD_PATTERN = "[0-9]{2}:[0-9]{2} ([Aa]|[Pp])[Mm]";
export const MAX_NUMBER_OF_TIME_SLOTS_PER_DAY = 3;

const isoDaysOfWeek = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
};

const getDayOfWeekValues = (dayOfWeek) => {
    return {
        timeSlots: [{ ...timeSlotObject, isDefault: true }],
        dayOfWeek,
        enabled: false,
        dayOfWeekName: isoDaysOfWeek[dayOfWeek],
    };
};

export const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

export const formInitialValues = {
    Monday: getDayOfWeekValues(1),
    Tuesday: getDayOfWeekValues(2),
    Wednesday: getDayOfWeekValues(3),
    Thursday: getDayOfWeekValues(4),
    Friday: getDayOfWeekValues(5),
    Saturday: getDayOfWeekValues(6),
    Sunday: getDayOfWeekValues(7),
    minAdvanceHours: 0,
    maxAdvanceHours: 720,
    appointmentDuration: 30,
    daysBookedOff: [],
    maximumAppointmentsPerTimeSlot: 1,
    appointmentsBufferTime: 0,
};

export const defaultLocation = { value: 0, title: "Virtual Schedule" };
