export const NOTIFICATION_HEADERS = {
    RETAILER_SCHEDULE: "Retailer Schedule",
    RETAILER_WIDGET_SETTINGS: "Widget Settings",
    RETAILER_QUESTIONNAIRE: "Questionnaire",
    RETAILER_SETTINGS: "Retailer Settings",
    CALENDAR_APPOINTMENT: "Calendar Appointment",
    EMAIL_CUSTOMIZATION: "Email customization",
    RETAILER_SUBSCRIPTION: "Retailer Subscription",
    SUBSCRIPTION_REQUEST: "Subscription Request",
    QUEUES_SETTINGS: "Queues",
    QUEUES_APPOINTMENTS: "Appointments",
    EMPLOYEES: "EMPLOYEES",
    WEBAPP: "Webapp",
    OOPS: "Oops...",
    SYNC: "Order Sync"
};
