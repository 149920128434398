import styles from 'src/components/loader/loader.module.scss';

function Loader({ message }) {
    return (
        <div className={styles.container}>
            <div className={styles.loader}></div>
            {!!message && <p>{message}</p>}
        </div>
    );
}

export default Loader;
