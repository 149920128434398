import {
    VALID_SUBSCRIPTION_STATUSES,
    UNPAID_SUBSCRIPTION_STATUSES,
} from "src/lib/constants";

export const isSubscriptionStatusValid = (user) => {
    if (user.role === "admin") {
        return true;
    }
    return VALID_SUBSCRIPTION_STATUSES.includes(user.subscriptionStatus);
};

export const isSubscriptionStatusPastDueOrUnpaid = (user) => {
    if (user.role === "admin") {
        return false;
    }

    return UNPAID_SUBSCRIPTION_STATUSES.includes(user.subscriptionStatus);
};

export const getLocalISODate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
};

export const getLocationsDropdownValues = (
    retailerLocations,
    user,
    allLocationsValue,
    virtualLocationsValue
) => {
    const locations = [];

    if (!user.location_lock) {
        locations.push(allLocationsValue);
        locations.push(virtualLocationsValue);
    }

    if (user.isShopifyUser && user.shouldHaveAllLocationsActive) {
        retailerLocations.forEach((location) => {
            locations.push({
                value: location.location_id,
                title: `${location.name} - ${location.address}`,
            });
        });
    } else {
        retailerLocations
            .filter((location) => location.billable && location.visible)
            .forEach((location) => {
                locations.push({
                    value: location.location_id,
                    title: `${location.name} - ${location.address}`,
                });
            });
    }

    return locations;
};


export const roundDecimals = (float) => {
    console.log(float)
    return Math.round((float + Number.EPSILON) * 100) / 100
}