import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Overlay, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createPopper } from '@popperjs/core';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay, differenceInMinutes, lastDayOfMonth, startOfMonth, endOfMonth, add, sub } from 'date-fns';
import clsx from 'clsx';

import Api from 'src/data/api';
import AppointmentForm from 'src/components/calendar/appointmentForm';
import * as calendarComponents from 'src/components/calendar/calendarComponents';
import styles from 'src/components/calendar/calendar.module.scss';
import { defaultCalendar, virtualCalendar } from './CalendarConstants';
import { saveAs } from 'file-saver';

import Flatpickr from "react-flatpickr";
import CloseIcon from "public/assets/close.svg";
import useScreenSize from 'src/hooks/useScreenSize';
import { MAX_MOBILE_SCREEN_WIDTH_PX } from 'src/constants/screenSizes';
import { getLocalISODate, getLocationsDropdownValues } from 'src/utils/helpers';


const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: { 'en-US': require('date-fns/locale/en-US') },
});

const getMappedCalendarsValues = (retailerLocations, isMasterView, user) => {
    if (isMasterView) {
        return [{ ...defaultCalendar }];
    }

    const calendarElements = getLocationsDropdownValues(
        retailerLocations,
        user,
        defaultCalendar,
        virtualCalendar
    );

    return calendarElements;
};

const ExportRange = ({ start, end, setStart, setEnd }) => {
    return (
        <div>
            <span>From:</span>
            <Flatpickr
                className="form-control"
                value={start}
                onChange={(selDate, dateStr) => setStart(selDate[0])}
                render={({ defaultValue, value, ...props }, inputRef) => (
                    <div className="flatpickrInputContainer">
                        <input
                            {...props}
                            defaultValue={defaultValue}
                            ref={inputRef}
                            placeholder="Select Start Date"
                            required
                        />
                    </div>
                )}
                options={{
                    enableTime: false,
                    dateFormat: "m/d/y",
                }}
            />
            <span>To: </span>
            <Flatpickr
                className="form-control"
                value={end}
                onChange={(selDate, dateStr) => setEnd(selDate[0])}
                render={({ defaultValue, value, ...props }, inputRef) => (
                    <div className="flatpickrInputContainer">
                        <input
                            {...props}
                            defaultValue={defaultValue}
                            ref={inputRef}
                            placeholder="Select Start Date"
                            required
                        />
                    </div>
                )}
                options={{
                    enableTime: false,
                    dateFormat: "m/d/Y",
                }}
            />
        </div>
    );
};

const ExportComponent = ({
    start,
    end,
    setStart,
    setEnd,
    exportAppointments,
}) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        setShow((prev) => !prev);
        setTarget(event.target);
    };

    const handleClose = () => {
        setShow(false)
    }

    return (
        <div className={styles.exportWrapper} ref={ref}>
            <Button
                className={styles.actions}
                onClick={handleClick}
                variant="outline-secondary"
                size="sm"
            >
                Export
            </Button>

            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
            >
                <Popover id="popover-contained">
                    <Popover.Content
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <button
                            className={styles.apptFormClose}
                            onClick={handleClose}
                            aria-label="Close"
                            style={{ marginLeft: "auto" }}
                        >
                            <CloseIcon />
                        </button>
                        <ExportRange
                            start={start}
                            end={end}
                            setStart={setStart}
                            setEnd={setEnd}
                        />
                        <Button
                            style={{ alignSelf: 'flex-start' }}
                            className={styles.actions}
                            onClick={() => exportAppointments(start, end)}
                            variant="outline-secondary"
                            size="sm"
                        >
                            Export
                        </Button>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
};

function Calendar({ isMasterView = false, user, retailerLocations }) {
    const screenSize = useScreenSize()
    const [isMobile, setIsMobile] = useState(() => screenSize.innerWidth <= MAX_MOBILE_SCREEN_WIDTH_PX)
    const [calendarView, setCalendarView] = useState(() => {
        return screenSize.innerWidth <= MAX_MOBILE_SCREEN_WIDTH_PX
            ? "day"
            : "month";
    });
    const [allowedViews, setAllowedViews] = useState(() => {
        return screenSize.innerWidth <= MAX_MOBILE_SCREEN_WIDTH_PX
            ? ["day", "agenda"]
            : ["month", "week", "day", "agenda"];
    })

    const [date, setDate] = useState(new Date());
    const [monthlyAppointmentsCount, setMonthlyAppointmentsCount] = useState(0);
    const [appointments, setAppointments] = useState([]);
    const [selectedAppt, setSelectedAppt] = useState(null);
    const selectedApptContainer = useRef(null);
    const popper = useRef(null);

    const [exportStartDate, setExportStartDate] = useState(startOfMonth(new Date()));
    const [exportEndDate, setExportEndDate] = useState(lastDayOfMonth(new Date()));

    const defCalendar = !user.location_lock
        ? defaultCalendar
        : {
            value: retailerLocations[0].location_id,
            title: retailerLocations[0].address,
        };

    const [currentCalendar, setCurrentCalendar] = useState({ ...defCalendar })

    const filteredAppointments = appointments.filter((appointment) => {
        if (currentCalendar.value === defaultCalendar.value) {
            return true;
        }

        if (currentCalendar.value === virtualCalendar.value) {
            return appointment.type === "virtual";
        }

        return (
            appointment.location_id === currentCalendar.value ||
            appointment.location_address === currentCalendar.title
        );
    });

    const mappedCalendarsValues = getMappedCalendarsValues(retailerLocations, isMasterView, user);

    const minWeekViewTime = new Date();
    minWeekViewTime.setHours(6, 0, 0, 0);
    const maxWeekViewTime = new Date();
    maxWeekViewTime.setHours(23, 0, 0, 0);

    const handleApptSelect = async (appt, e) => {
        if (popper.current !== null) {
            popper.current.destroy();
            setSelectedAppt(null);
        }
        popper.current = createPopper(e.currentTarget, selectedApptContainer.current, {
            placement: 'right-start',
            modifiers: [
                { name: 'offset', options: { offset: [0, 10] } },
                {
                    name: 'flip', options: {
                        fallbackPlacements: ['bottom-start', 'left-start', 'top-start'],
                        padding: 25,
                        allowedAutoPlacements: 'auto',
                    }
                },
                {
                    name: 'preventOverflow', options: {
                        altAxis: true,
                        padding: 25,
                    }
                },
                { name: 'arrow', options: { padding: 10 } },
            ],
        });

        if (appt) {
            const { data } = await Api.request({ url: `/retailer/appointment/${appt.appointment_id}` });
            setSelectedAppt({ ...data, appt_date: parse(data.appt_date, "yyyy-MM-dd", new Date()) })
        } else {
            setSelectedAppt({});
        }

        return popper.current.update();
    };

    const closeAppt = async ({ update = false }) => {
        setSelectedAppt(null);
        if (popper.current) {
            popper.current.destroy();
        }
        if (update) {
            const { data } = await Api.request({
                url: "/retailer/appointments",
                params: {
                    startDate: startOfMonth(date).toISOString(),
                    endDate: endOfMonth(date).toISOString(),
                },
            });
            setAppointments(data);
        }
    };

    const exportAppointments = async (from, to) => {
        const result = await Api.request({
            method: "GET",
            url: "/retailer/appointments/export",
            params: { from: getLocalISODate(from), to: getLocalISODate(to), locationId: currentCalendar.value },
            responseType: "blob",
        });
        saveAs(result.data, "appointments.csv");
    };

    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const { data } = await Api.request({
                    url: `/${isMasterView ? 'admin' : 'retailer'}/appointments`,
                    params: {
                        startDate: sub(startOfMonth(date), { days: 7 }).toISOString(),
                        endDate: add(endOfMonth(date), { days: 7 }).toISOString(),
                    },
                });

                setAppointments(data);
            } catch (err) {
                console.error(err);
            }
        };
        const fetchAppointmentsForGivenMonth = async () => {
            try {
                const { data } = await Api.request({
                    url: `/${isMasterView ? 'admin' : 'retailer'}/appointments`,
                    params: {
                        startDate: startOfMonth(date).toISOString(),
                        endDate: endOfMonth(date).toISOString(),
                    },
                });

                setMonthlyAppointmentsCount(data.length);
            } catch (err) {
                console.error(err);
            }
        }
        fetchAppointmentsForGivenMonth();
        fetchAppointments();
    }, [date]);

    const handleViewChange = (view) => {
        setCalendarView(view)
    }

    useEffect(() => {
        if (screenSize.innerWidth <= MAX_MOBILE_SCREEN_WIDTH_PX) {
            setIsMobile(true);
            return;
        }

        if (screenSize.innerWidth > MAX_MOBILE_SCREEN_WIDTH_PX) {
            setIsMobile(false);
            return;
        }
    }, [screenSize]);

    useEffect(() => {
        const views = isMobile
            ? ["day", "agenda"]
            : ["month", "week", "day", "agenda"];

        if (!views.includes(calendarView)) {
            setCalendarView(views[0]);
        }

        setAllowedViews(views);
    }, [isMobile]);

    return (
        <div className={styles.calendarWrapper}>
            <div className={styles.calendarHeader}>
                <div className={styles.calendarLegend}>
                    <strong>Legend:</strong>
                    <div className={styles.calendarLegendVirtual}>Virtual</div>
                    <div className={styles.calendarLegendInstore}>In Person</div>
                    {!!user?.flags?.employee_schedules_enabled &&
                        <div className={styles.calendarLegendEmployee}>Employee</div>
                    }
                </div>
                {!isMasterView &&
                    <Form.Group controlId="input-location" className="mb-2">
                        <Form.Control
                            value={currentCalendar.value}
                            as="select"
                            custom
                            // style={{ minWidth: 0, maxWidth: 400 }}
                            className={styles.locationsSelect}
                            onChange={(e) => {
                                const location = mappedCalendarsValues.find(
                                    (location) => location.value == e.target.value
                                );

                                setCurrentCalendar(location);
                            }}
                        >
                            {mappedCalendarsValues.map((location) => (
                                <option key={location.value} value={location.value}>
                                    {location.title}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                }
                {!isMasterView &&
                    <div>
                        <Button className={styles.actions} variant="outline-secondary" size="sm" onClick={e => handleApptSelect(null, e)}>Create new appointment</Button>
                    </div>
                }
                {/* {user.role === "admin" && 
                    <div>
                        Current monthly appointments: {monthlyAppointmentsCount}
                    </div>
                } */}
                <ExportComponent
                    start={exportStartDate}
                    end={exportEndDate}
                    setStart={setExportStartDate}
                    setEnd={setExportEndDate}
                    exportAppointments={exportAppointments}
                />
            </div>
            <BigCalendar
                localizer={localizer}
                events={filteredAppointments.map(appt => ({
                    ...appt,
                    start: new Date(appt.start),
                    end: new Date(appt.end),
                    allDay: false,
                }))}
                components={{
                    event: calendarComponents.Event,
                    week: {
                        event: calendarComponents.Event,
                    },
                    agenda: {
                        event: (props) => <calendarComponents.AgendaEvent {...props} onSelectEvent={handleApptSelect} />,
                    },
                }}
                formats={{
                    eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
                        return `${localizer.format(start, 'h:mmaaaaa')} (${differenceInMinutes(end, start)}m)`;
                    },
                }}
                eventPropGetter={(event) => ({
                    className: styles[`calendarEventType${event.employee_id ? "Employee" : event.type === 'instore' ? 'Instore' : 'Virtual'}`],
                })}
                startAccessor="start"
                endAccessor="end"
                allDayAccessor="allDay"
                min={minWeekViewTime}
                max={maxWeekViewTime}
                onSelectEvent={handleApptSelect}
                onView={(view) => {
                    closeAppt(view);
                    handleViewChange(view);
                }}
                onNavigate={(newDate) => {
                    closeAppt(newDate)
                    setDate(newDate)
                }}
                views={allowedViews}
                view={calendarView}
            />

            <div ref={selectedApptContainer} className={clsx(styles.selectedApptContainer, { [styles.selectedApptLoaded]: selectedAppt !== null })}>
                <div className={styles.selectedApptArrow} data-popper-arrow=""></div>
                {selectedAppt &&
                    <AppointmentForm
                        user={user}
                        appointment={selectedAppt}
                        onChange={changes => setSelectedAppt({ ...selectedAppt, ...changes })}
                        onRequestClose={closeAppt}
                        readOnly={isMasterView}
                    />
                }
            </div>
        </div>
    );
}

export default connect(store => ({ user: store.user.data }))(Calendar);
